import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>DEVOPS ENGINEER</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>Knowledge of cloud standards, protocols, and technologies</li>
          <li>Familiarity with cloud services from AWS</li>
          <li>Experience setting up CI/CD pipelines</li>
          <li>Strong hands-on experience with containers, including orchestration - Docker and AWS ECS Fargate preferred</li>
          <li>Data & security driven mindset</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
